.title{
    font-size: 22px;
    font-weight: bold;
    text-align: center;
}

.subtitle{
    font-size: 18px;
    font-weight: bold;
    text-align: center;
}