.button{
    background-color: #00eead;
    border: none;
    color: #6147c5;
    font-weight: bold;
    padding-left: 20px;
    padding-right: 20px;
    margin: 15px 0;
}

.fullWidth{
    width: 100%;
}