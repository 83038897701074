.title{
    font-size: 20px;
    text-align: center;
}

.subtitle{
    font-size: 18px;
    text-align: center;
}

.item{
    border: 1px solid white;
    padding: 5px;
    margin:10px 0;
    border-radius: 5px;
}